import React from "react";
import SpotlightContainer from "../spotlight";
import Nest from "../../../images/nest.png";
import Gitlab from "../../../images/gitlab.png";
import Docker from "../../../images/docker.png";
import Image from "../../../images/sd2.png";

function Project() {
  const jobData = {
    jobName: "Flutterwave",
    button: "View",
    link: "https://flutterwave.com",
    image: Image,
    stack: [
      {
        name: "NestJS",
        image: Nest,
      },
      {
        name: "Gitlab",
        image: Gitlab,
      },
      {
        name: "Docker",
        image: Docker,
      },
    ],

    description:
      "I refactored and optimized the third-party Veriff identity verification integration to address critical issues. This project involved troubleshooting production errors, reducing code complexity, and rewriting comprehensive documentation to ensure clarity and ease of use across departments. The refactor not only stabilized the system but also improved maintainability and cross-departmental collaboration.",
    roles: [],
  };

  return <SpotlightContainer jobData={jobData} />;
}
export default Project;
